import React from 'react'

const SellerSection = ({data}) => {
  return (
    <>
        <div className="seller conatiner-fluid mb-md-5 mb-lg-0" style={{ backgroundImage: `url("../images/seller/Become\ Seller\ 1.png")`, minHeight: '480px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} >
        <div className="container">
          <div className="row gap-lg-5 align-items-center justify-content-center">
            <div className="col-9 col-lg-5 ms-lg-auto mb-4 mb-lg-0 text-center position-relative">
              <img src={data?.home?.slrsmimage?.img_url} alt="seller" className="img-fluid position-absolute seller-img-1" style={{top: '-20px', left: '-50px'}} />
              <img src={data?.home?.slrimage?.img_url} alt="seller" className="img-fluid" />
            </div>
            <div className="col-md-10 col-lg-6 px-md-1 px-lg-0">
              <h2 className="fw-bold fs-3">{data?.home?.data?.slr_title}</h2>
              <p style={{fontSize: '15px'}}>{data?.home?.data?.slr_des}</p>
              <div className="row mt-md-2 mt-lg-4">
                <div className="col-2 col-lg-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} fill="currentColor" className="bi bi-camera" viewBox="0 0 16 16">
                    <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                    <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                  </svg>
                </div>
                <div className="col-10 col-lg-11">
                  <h4 className="fw-bold fs-6">{data?.home?.data?.slr_p_title_1}</h4>
                  <p style={{fontSize: '15px'}}>{data?.home?.data?.slr_p_des_1}</p>
                </div>
              </div>
              <div className="row mt-md-1 mt-lg-3">
                <div className="col-2 col-lg-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} fill="currentColor" className="bi bi-card-image" viewBox="0 0 16 16">
                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                  </svg>
                </div>
                <div className="col-10 col-lg-11">
                  <h4 className="fw-bold fs-6">{data?.home?.data?.slr_p_title_2}</h4>
                  <p style={{fontSize: '15px'}}>{data?.home?.data?.slr_p_des_2}</p>
                </div>
              </div>
              <button className="btn rounded-pill text-white px-3 readmorebtn mt-md-1 mtlg--3">Become
                Seller</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SellerSection