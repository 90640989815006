import React from 'react';

const AboutSection  = ({data}) => {
  return (
    <>
      <div className="about">
          <div className="container">
            <h2 className="py-2 p-lg-5 p-md-3 text-center fw-bold fs-3">{data?.home?.data?.abt_bg_title}</h2>
            <div className="row text-align-start">
              <div className="col-md-6 ms-auto">
                <h2 className="fw-bold fs-3">{data?.home?.data?.abt_title}</h2>
                <p className="fw-normal">{data?.home?.data?.abt_desc}</p>
                <button className="btn rounded-pill text-white px-3 readmorebtn mb-lg-5 mb-2">Read More <i className="fa-solid fa-angle-down text-white" /></button>
                <div className="row">
                  <div className="col-auto">
                    <h4 className="fw-bold">{data?.home?.data?.abt_counter_1}</h4>
                    <p>{data?.home?.data?.abt_counter_1_txt}</p>
                  </div>
                  <div className="col-auto">
                    <h4 className="fw-bold">{data?.home?.data?.abt_counter_2}</h4>
                    <p>{data?.home?.data?.abt_counter_2_txt}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <img src={data?.home?.abtimage?.img_url??"/images/about/About Nasksoft.png"} alt="About" className="img-fluid" />
              </div>
            </div>
          </div>
      </div>
    </>
  )
}

export default AboutSection;