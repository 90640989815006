import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { BASE_URL } from "../../constants";
import { Loader } from "../Loader";

const ServiceSection = (props) => {
  const [data, setData] = useState([]);
  const location = useLocation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const subCategoryId = searchParams.get("subCategoryId");
    setLoader(true);
    if (id && id === "all") {
      fetch(`${BASE_URL}/service-list/all-services`).then((result) => {
        result.json().then((resp) => {
          setLoader(false);
          setData(resp);
        });
      });
    }
    if (id && id !== "all" && !subCategoryId) {
      fetch(`${BASE_URL}/service-list/search-by-category/${id}`).then(
        (result) => {
          result.json().then((resp) => {
            setLoader(false);
            setData(resp);
          });
        }
      );
    }
    if (id && id !== "all" && subCategoryId) {
      fetch(
        `${BASE_URL}/service-list/category-subcategory-search/${id}/${subCategoryId}`
      ).then((result) => {
        result.json().then((resp) => {
          setLoader(false);
          setData(resp.all_services.data);
        });
      });
    }
  }, [searchParams, id]);

  return (
    <>
      <section className="services">
        <div className="container px-4 px-md-0">
          {loader && <Loader />}
          {data &&
            data.map((items, index) => (
              <div
                key={index}
                className="row align-items-center justify-content-center my-md-5 px-xl-5 mb-5"
              >
                <div className="col-7 col-md-3">
                  <Link
                    to={`/service-details/${items.id}`}
                    className="navbar-brand"
                  >
                    <img
                      src={
                        items?.image
                          ? items?.image
                          : "/images/Blog-banners/b-update1.jpg"
                      }
                      alt=""
                      className="img-fluid"
                      style={{ minHeight: "250px", objectFit: "cover" }}
                    />
                  </Link>
                </div>
                <div className="col-12 col-md-5 col-xl-6 my-3 my-md-0">
                  <h5 className="m-0 fw-semibold pb-2 pt-2">{items?.title}</h5>
                  <p className="text-capitalize m-0">{items?.category?.name}</p>
                  <p className="text-capitalize m-0 price-color">
                    Price: {items?.price} PKR
                  </p>
                  <div className="hstack gap-3 mt-xl-5 pt-md-5 pt-3">
                    <div>
                      <h5 className="m-0 fw-semibold">Delivery In</h5>
                      <p className="m-0">{items?.delivery_days} Days</p>
                    </div>
                    <div className="vr" />
                    <div>
                      <h5 className="m-0 fw-semibold">
                        {items?.service_city?.service_city}
                      </h5>
                      <p className="m-0">{items?.seller?.address}</p>
                    </div>
                  </div>
                </div>
                <div className="col-7 col-md-3 col-xl-2 p-0">
                  <div className="vstack gap-3">
                    <button type="button" className="btn">
                      View Profile
                    </button>
                    <Link
                      to={`/booking/${items.seller_id}/${items.id}`}
                      className="btn py-2 mb-3 mb-md-0"
                    >
                      Booking for Photoshot
                    </Link>
                    {/* <button type="button" className="btn py-2 mb-3 mb-md-0">Booking for Photoshot</button> */}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </section>
    </>
  );
};

export default ServiceSection;
