import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../constants";
const OurServicesNav = () => {
    const [data, setData]=useState([]);
    useEffect(()=>{
      fetch(`${BASE_URL}/category`).then((result)=>{
        result.json().then((resp)=>{
          setData(resp)
        })
      })
    },[]);

  return (
    <>
       <li className="nav-item dropdown px-1">
            <Link className="nav-link rounded-pill px-2 py-lg-1 px-xl-3" to="/services/all">
                Our Services
                <i className="fa-solid fa-angle-down"></i>
            </Link>

            {/* <ul className="dropdown-menu">
                {
                    data?.map((items) =>
                    <li><Link className="dropdown-item" to={items.id} >{ items.name }</Link></li>
                    )
                }
              
            </ul> */}
            
            <span className="dropdown-icon">
                <i className="fa-solid fa-angle-down"></i>
            </span>
        </li>
    </>
  )
}

export default OurServicesNav
