import React from 'react'

const BannerSection = ({data}) => {
  return (
    <>
     <div className="blog-page">
        <div id="carouselExampleFade" className="carousel slide carousel-fade main-bnr" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={data?.page_bg_image?.img_url} className="d-block w-100" alt="..." />
              <div className="banner-content">
                <h1 className="text-white">{data?.data?.page_title}</h1>
                <p className="text-white" style={{letterSpacing: '1.2px'}}>Photography is about capturing moments you will want to<br /> remember forever</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BannerSection