import React from 'react'
import ServiceDetail from '../components/ServiceDetail/ServiceDetailSection';


const ServiceDetails = () => {
  return (
    <>
     <div className="service-page">
        <ServiceDetail />
    </div>
    </>
  )
}

export default ServiceDetails
