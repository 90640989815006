import React from 'react'

const BannerSection = () => {
  return (
    <>
        <div id="carouselExampleFade" className="carousel slide carousel-fade main-bnr" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src="images/Blog-banners/main banner.jpg" className="d-block w-100" alt="..." />
                    <div className="banner-content">
                    <h1 className="text-white">Blog</h1>
                    <p className="text-white" style={{letterSpacing: '1.2px'}}>The Greatest Photographers are
                        motivated<br /> by human feelings</p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default BannerSection