import React, { useEffect, useState } from "react";
import { BASE_URL } from '../constants'
import { Link, useParams } from 'react-router-dom';
import moment from "moment";
import parse from 'html-react-parser';

const PortfolioDetails = () => {

    const [data, setData] = useState([]);
    const { id } = useParams();

    const getData = async () => {
      const request = await fetch(`${BASE_URL}/album-details/${id}`);
      const result = await request.json();
      setData(result);
      console.log(result)
    }

    useEffect(() => {
      getData()
    },[id])
  
    let itemList = [];
    data?.service_benifits?.forEach((item, index) => {
      itemList.push(<div key={index} className="row ">
        <div className="col-12 pe-0 attri">
          <h4><i className="fa fa-check" /> {item.benifits}</h4>
        </div>
      </div>)
    })
  
    let includeList = [];
    data?.service_includes?.forEach((item, index) => {
      includeList.push(<div key={index} className="row ">
        <div className="col-12 pe-0 attri">
          <h4><i className="fa fa-check" /> {item.include_service_title}</h4>
        </div>
      </div>)
    })
  
  


  return (
    <>
        <div className="container">
            <div className="row gap-4 gap-xl-5 pt-5 justify-content-center">
              
              
              {/*-------- Blog Content Section Start -------*/}
              <div className="col-lg-8 pe-md-4 pe-xl-5 blog-content">
                <img src={ data?.album_image?.img_url } alt="....." className="img-fluid" />
                {/*---- banner footer Start -----*/}
                <div className="row bnr-footer py-4">
                  {/* <div className="col-auto">
                    <p><i className="fa-solid fa-user-tie" /> Daniele Custor</p>
                  </div>
                  <div className="col-auto">
                    <p><i className="bi bi-calendar3" /> 25 June 2022</p>
                  </div> */}
                  <div className="ms-auto col-auto">
                    {/* <p>Share Now
                      <a href="#"><i className="fa-brands fa-facebook-f" /></a>
                      <a href="#"><i className="fa-brands fa-twitter" /></a>
                      <a href="#"><i className="fa-brands fa-instagram" /></a>
                      <a href="#"><i className="fa-brands fa-google-plus-g" /></a>
                    </p> */}
                  </div>
                </div>
                {/*---- banner footer End -----*/}
                {/*---- Blog detail passage Start -----*/}
                <div className="row blog-passage">
                  <div className="col-12">
                    <h4 className="lh-base fw-semibold"> { data?.data?.title } </h4>
                    <p className="lh-base pt-2">
                    {data?.data?.description} </p>
                    <p className="p-3 Tags"><span className="fw-semibold">{ data?.data?.type } </span></p>
                   
                  </div>
                </div>
                <div className="row ">
                <div className="col-md-6">
                {parse(data?.gallery??'')}
                </div>
                    </div>
                {/*---- Blog detail passage Start -----*/}
           
      
              </div>

              {/*-------- Blog Widget Section Start -------*/}
              <div className="col-lg-3 blog-widget">
                {/*----- Recent Shoots Section Start -------*/}
                <div className="recent-shoots bg-white rounded-5 shadow-sm p-4 mb-5">
                  <h5 className="mb-3">Recent Shoots</h5>
                  <div className="row gap-3 border-bottom mx-1 py-3 mb-4">
                    <div className="col-3 p-0">
                      <img src="images/Blog-detail-page/post-1.png" alt="" />
                    </div>
                    <div className="col-8 pe-0">
                      <h6>Outdoor Photoshoot</h6>
                      <p>Capture the beauty in simplicity</p>
                      <span>15th June 2022</span>
                    </div>
                  </div>
                  <div className="row gap-3 border-bottom mx-1 py-3 mb-4">
                    <div className="col-3 p-0">
                      <img src="images/Blog-detail-page/post-2.png" alt="" />
                    </div>
                    <div className="col-8 pe-0">
                      <h6>Wedding Photoshoot</h6>
                      <p>Capturing your special moments</p>
                      <span>23rd June 2022</span>
                    </div>
                  </div>
                  <div className="row gap-3 border-bottom mx-1 py-3 mb-5">
                    <div className="col-3 p-0">
                      <img src="images/Blog-detail-page/post-3.png" alt="" />
                    </div>
                    <div className="col-8 pe-0">
                      <h6>Couple Photoshoot</h6>
                      <p>Photographing your moments forever</p>
                      <span>24th June 2022</span>
                    </div>
                  </div>
                </div>
                {/*----- Recent Shoots Section End --------*/}
                {/*------- Category Section Start ---------*/}
                <div className="category-section bg-white rounded-5 shadow-sm p-4 mb-5">
                  <h5 className="mb-3">Category</h5>
                  <ul className="list-unstyled pb-3">
                    <li><a href="#">Outdoor Shoot</a></li>
                    <li><a href="#">Indoor Shoot</a></li>
                    <li><a href="#">Studio Shoot</a></li>
                    <li><a href="#">Product Shoot</a></li>
                    <li><a href="#">Wedding Shoot</a></li>
                    <li><a href="#">Couple Shoot</a></li>
                    <li><a href="#">Birthday Shoot</a></li>
                  </ul>
                </div>
                {/*------- Category Section End ---------*/}
                {/*------- Widget Contact Us banner Start -----*/}
             
                {/*------- Widget Contact Us banner Start -----*/}
              </div>
              {/* Blog Widget Section End  */}
            </div>
        </div>
    </>
  )
}

export default PortfolioDetails
