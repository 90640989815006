import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import '../index.css';
import OurServicesNav from './OurServicesNav';

const Navbar = () => {
    const loggedInUser = localStorage.getItem("auth");
    const navigate = useNavigate();
    function logout (){
        localStorage.removeItem('auth');
        navigate('/');
      }
  return (
    <>
        <nav className="navbar navbar-expand-lg ps-lg-3">
            <div className="container-fluid px-lg-2 px-xl-5 p-2">

                <Link className="navbar-brand" to="/" >
                    <img src="images/logo.png" alt="" />
                </Link>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse mt-2 mt-lg-0" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item px-1">
                            <Link to="/" className="nav-link rounded-pill px-2 py-lg-1 px-xl-3"> Home</Link>
                        </li>
                        <li className="nav-item px-1">
                            <Link className="nav-link rounded-pill px-2 py-lg-1 px-xl-3" to="/about">About Us</Link>
                        </li>

                       <OurServicesNav />

                        <li className="nav-item px-1">
                            <Link className="nav-link rounded-pill px-2 py-lg-1 px-xl-3" to="/vendor">Vendor</Link>
                        </li>
                        <li className="nav-item px-1">
                            <Link className="nav-link rounded-pill px-2 py-lg-1 px-xl-3" to="/portfolio">Portfolio</Link>
                        </li>
                        <li className="nav-item px-1">
                            <Link className="nav-link rounded-pill px-2 py-lg-1 px-xl-3" to="/blog">Blog</Link>
                        </li>
                        <li className="nav-item px-1">
                            <Link className="nav-link rounded-pill px-2 py-lg-1 px-xl-3" to="/contact">Contact</Link>
                        </li>
                        {!loggedInUser ? (
                        <li className="nav-item border-2 border-end px-1">
                            <Link className="nav-link rounded-pill px-2 py-lg-1 px-xl-3 d-flex" to="/login"><i
                                    className="bi bi-person-circle pe-2"></i>
                                Login</Link>
                        </li>
                        ) : (
                            <li className="nav-item px-1">
                            <Link className="nav-link rounded-pill px-2 py-lg-1 px-xl-3" to="/user-dashboard">Dashboard</Link>
                        </li>
                            )}
                        {!loggedInUser ? (
                        <li className="nav-item px-1">
                            <Link className="nav-link rounded-pill px-2 py-lg-1 px-xl-3" to="/register">Register</Link>
                        </li>
                         ) : (
                            <li className="nav-item px-1">
                            <Link onClick={()=>logout()} className="nav-link rounded-pill px-2 py-lg-1 px-xl-3" to="/user-dashboard">Logout</Link>
                        </li>
                            )}
                           
                    </ul>
                </div>
            </div>
        </nav> 
    </>
  )
}

export default Navbar