import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export const Carousel = ({ categories }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(categories);
   
  }, [categories]);
  return (
<>
  {data.length > 0 && (
    <OwlCarousel
      className="owl-theme owlSliderRow"
      items={6}
      margin={30}
      lazyLoad={true}
      autoplay={true}
      loop
    >
      {data?.map((items, index) => {
        return (
          <div className="item sliderItemBox rounded-5" key={index+"item"}>
            <Link className="SliderCardText" to={`/services/${items.id}`}>
              <div
                className="card-body px-1 pt-0 pb-2 text-center"
                style={{ textAlign: "center" }}
              >
                <img
                  src={items.mobile_icon}
                  style={{
                    height: "100px",
                    objectFit: "contain",
                    marginBottom: "15px",
                  }}
                />
                {items.name}
              </div>
            </Link>
          </div>
        );
      })}
    </OwlCarousel>
  
  )}
  </>
  );
};
