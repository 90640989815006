import React from 'react'
import RegistrationForm from '../components/RegisterSection/RegistrationForm'

const Register = () => {
  return (
    <>
      <RegistrationForm />
    </>
  )
}

export default Register;