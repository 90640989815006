import VendorProfileSection from '../components/VendorProfileSection/VendorProfileSection'
// import BannerSection from '../components/VendorProfileSection/BannerSection'
// import InfoSection from '../components/VendorProfileSection/InfoSection'
// import SliderSection from '../components/VendorProfileSection/SliderSection'
// import ClientSection from '../components/VendorSection/ClientSection'
// import FormSection from '../components/VendorSection/FormSection'

const VendorProfile = () => {
  return (
      <>
          <div className="vendorProfile-page">
            
            <VendorProfileSection />

          </div>
      </>
  )
}

export default VendorProfile