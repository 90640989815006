import React from 'react'

const MarketplaceSection = ({data}) => {
  return (
    <>
       <div className="marketplace mt-md-0 mt-5 mb-md-5">
        <div className="container text-center">
          <h2 className="fw-bold fs-3 mb-3">{data?.home?.data?.mrk_title}</h2>
          <p style={{fontSize: '14px'}}>{data?.home?.data?.mrk_des}</p>
          <div className="row gy-4 gap-3 mx-auto justify-content-center mt-4">
            <div className="col-md-5 col-xl-3">
              <div className="card rounded-4 pt-4 border-0">
                <i className="fa-solid fa-people-group" />
                <div className="card-body">
                  <h5 className="card-title">{data?.home?.data?.mrk_card_title_1}</h5>
                  <p className="card-text" style={{fontSize: '14px'}}>{data?.home?.data?.mrk_card_des_1}</p>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-xl-3">
              <div className="card rounded-4 pt-4 border-0">
                <i className="fa-solid fa-users-gear" />
                <div className="card-body">
                  <h5 className="card-title">{data?.home?.data?.mrk_card_title_2}</h5>
                  <p className="card-text" style={{fontSize: '14px'}}>{data?.home?.data?.mrk_card_des_2}</p>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-xl-3">
              <div className="card rounded-4 pt-4 border-0">
                <i className="fa-solid fa-magnifying-glass-chart" />
                <div className="card-body">
                  <h5 className="card-title">{data?.home?.data?.mrk_card_title_3}</h5>
                  <p className="card-text" style={{fontSize: '14px'}}>{data?.home?.data?.mrk_card_des_3}</p>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-xl-3">
              <div className="card rounded-4 pt-4 border-0">
                <i className="fa-solid fa-box-open" />
                <div className="card-body">
                  <h5 className="card-title">{data?.home?.data?.mrk_card_title_4}</h5>
                  <p className="card-text" style={{fontSize: '14px'}}>{data?.home?.data?.mrk_card_des_4}</p>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-xl-3">
              <div className="card rounded-4 pt-4 border-0">
                <i className="fa-solid fa-tty" />
                <div className="card-body">
                  <h5 className="card-title">{data?.home?.data?.mrk_card_title_5}</h5>
                  <p className="card-text" style={{fontSize: '14px'}}>{data?.home?.data?.mrk_card_des_5}</p>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-xl-3">
              <div className="card rounded-4 pt-4 border-0">
                <i className="fa-solid fa-bullhorn" />
                <div className="card-body">
                  <h5 className="card-title">{data?.home?.data?.mrk_card_title_6}</h5>
                  <p className="card-text" style={{fontSize: '14px'}}>{data?.home?.data?.mrk_card_des_6}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </>
  )
}

export default MarketplaceSection