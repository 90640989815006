import React from 'react'
import LoginFormSection from '../components/LoginSection/LoginFormSection'

const Login = () => {
  return (
    <>
      <LoginFormSection/>
    </>
  )
}

export default Login